import React, { useState, useEffect } from "react";
import VirtualAssistantChat from "./virtual-assistant-chat";
import SignIn from "../section-components/signIn";
import Register from "../section-components/register";
import EditProfile from "./edit-profile";
import VirtualAssistantTutorial from "../section-components/virtual-assistant-tutorial";
import axios from "axios";

const VirtualAssistant = ({
  navbarId,
  obtainedPoints,
  pageType,
  onTutorialTextChange,
}) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let isUserDataFetched = false;
  const isEditTutorialSkipped = localStorage.getItem("isEditTutorialSkipped");
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [signIn, setSignIn] = useState(false);
  const [register, setRegister] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [isLoged, setIsLoged] = useState(false);
  const [user, setUser] = useState([]);
  const [points, setPoints] = useState([]);
  const [medals, setMedals] = useState([]);
  const [currentText, setCurrentText] = useState(0);
  const apiUrl =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/token/";
  const apiUrlPoints =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/points/";
  const apiUrlMedals =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/medals/";
  const allCategories = ["Adventure", "Culture", "Hiking"];

  useEffect(() => {
    async function fetchData() {
      try {
        const currentToken = window.localStorage.getItem("token");
        if (currentToken != null) {
          const dataUser = await axios.get(apiUrl + currentToken);
          const actualUser = dataUser.data.data;

          // Retrieving points
          try {
            const userPoints = await axios.get(apiUrlPoints + actualUser.id);
            setPoints(userPoints.data);
          } catch (error) {
            console.error("Error fetching user points: ", error.response.data);
          }

          // Retrieving medals
          try {
            const userMedals = await axios.get(apiUrlMedals + actualUser.id);
            setMedals(userMedals.data);
          } catch (error) {
            console.error("Error fetching user medals: ", error.response.data);
          }

          setUser(actualUser);
          setIsLoged(true);
        }
      } catch (error) {
        console.error(error.response.data);
      }
    }

    fetchData();
  }, [obtainedPoints]);

  const handleCloseModal = (dataFromModal) => {
    setSignIn(dataFromModal);
  };

  const handleCloseModalRegister = (dataFromModalRegister) => {
    setRegister(dataFromModalRegister);
  };

  const handleCloseEditProfile = (dataFromModalEditProfile) => {
    setEditProfile(dataFromModalEditProfile);
  };

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const toggleUserInfo = () => {
    setUserInfo(!userInfo);
    localStorage.setItem("isEditTutorialSkipped", true);
  };

  const toggleSignIn = () => {
    setSignIn(!signIn);
  };

  const toggleRegister = () => {
    setRegister(!register);
  };

  const toggleEditProfile = () => {
    setEditProfile(!editProfile);
  };

  const toggleIsLogged = () => {
    setIsLoged(!isLoged);
    window.localStorage.removeItem("token");
    // window.location.reload();
    setIsPanelOpen(true);
  };

  const handleTextChange = (data) => {
    setCurrentText(data);
  };

  useEffect(() => {
    onTutorialTextChange(currentText);
  }, [handleTextChange]);

  return (
    <div>
      {register && (
        <Register
          onCloseRegister={handleCloseModalRegister}
          register={register}
          onCloseSignIn={handleCloseModal}
          signIn={signIn}
        />
      )}
      {signIn && (
        <SignIn
          onCloseSignIn={handleCloseModal}
          signIn={signIn}
          onCloseRegister={handleCloseModalRegister}
          register={register}
        />
      )}
      {editProfile && (
        <EditProfile
          onCloseEditProfile={handleCloseEditProfile}
          editProfile={editProfile}
        />
      )}
      <VirtualAssistantTutorial
        onTextChange={handleTextChange}
        navbarId={navbarId}
        isPanelOpen={isPanelOpen}
        pageType={pageType}
      />
      <div className='virtual-assistant-container'>
        <div className='virtual-assistant-circle' onClick={togglePanel}>
          <img
            src={publicUrl + "assets/img/toucan-img/virtual-assistant-img.png"}
            alt='Virtual Assistant'
          />
        </div>

        {isPanelOpen && (
          <div>
            {isLoged ? (
              <div className='virtual-assistant-panel'>
                {/* Header with user information */}
                {!userInfo ? (
                  <div className='panel-header'>
                    {/* Profile image container */}
                    <div>
                      {/* User image */}
                      <img
                        src={publicUrl + user.image}
                        alt='profile-icon'
                        className='user-icon-assistant'
                      />
                      {/* Edit profile icon */}
                      <img
                        src={publicUrl + "assets/svg/edit-profile-icon.svg"}
                        alt='edit-profile-icon'
                        onClick={toggleUserInfo}
                        className='edit-profile-icon'
                      />
                      <div
                        className={
                          isEditTutorialSkipped ? "d-none" : "edit-profile-info"
                        }
                      >
                        Click here to edit your profile or sign out
                      </div>
                    </div>
                    {/* Username */}
                    <div className='user-name'>{user.name}</div>
                    {/* Container for the medals */}
                    <div className='medals-container'>
                      {allCategories.map((categoryName) => {
                        const matchingPoint = points.find(
                          (point) => point.category.name === categoryName
                        );

                        const { id, amount } = matchingPoint || {
                          id: null,
                          amount: 0,
                        };

                        const matchingMedal = medals.find(
                          (medal) => medal.name === `${categoryName} Medal`
                        );

                        const medalIcon = matchingMedal
                          ? `${publicUrl}assets/svg/${categoryName.toLowerCase()}-obtained-logo.svg`
                          : `${publicUrl}assets/svg/${categoryName.toLowerCase()}-unobtained-logo.svg`;

                        return (
                          <div key={id} className='medal-item'>
                            <p className='medal-text'>{amount}</p>
                            <img
                              className='medal-icon'
                              src={medalIcon}
                              alt='category-medal'
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className='panel-header'>
                    {/* Profile image container */}
                    <div>
                      {/* User image */}
                      <img
                        src={publicUrl + user.image}
                        alt='profile-icon'
                        className='user-icon-assistant'
                      />
                      {/* Edit profile icon */}
                      <img
                        src={publicUrl + "assets/svg/arrow-down.svg"}
                        alt='edit-profile-icon'
                        onClick={toggleUserInfo}
                        className='back-to-profile-icon'
                      />
                    </div>
                    <button
                      className='profile-manage-buttons-border'
                      onClick={toggleEditProfile}
                    >
                      Edit Profile
                    </button>
                    <button
                      className='profile-manage-buttons'
                      onClick={toggleIsLogged}
                    >
                      Sign Out
                    </button>
                  </div>
                )}
                {/* Tabs */}
                <div className='tab-container'>
                  {/* tab */}
                  <button
                    className={activeTab === "tab1" ? "active" : ""}
                    onClick={() => changeTab("tab1")}
                  >
                    Introduction
                  </button>
                  {/* tab */}
                  <button
                    className={activeTab === "tab2" ? "active" : ""}
                    onClick={() => changeTab("tab2")}
                  >
                    Plans
                  </button>
                  {/* tab */}
                  <button
                    className={activeTab === "tab3" ? "active" : ""}
                    onClick={() => changeTab("tab3")}
                  >
                    Profile
                  </button>
                  {/* Add more tabs as needed */}
                </div>

                {/* Content shown depending on the selected tab */}
                <div className='tab-content'>
                  {activeTab === "tab1" && (
                    <div>
                      {/* Content for Tab 1 */}
                      <VirtualAssistantChat
                        steps={[
                          {
                            id: "1",
                            message: "What are you interested in?",
                            trigger: "2",
                          },
                          {
                            id: "2",
                            options: [
                              {
                                value: 1,
                                label: "What is  this WebApp for?",
                                trigger: "3",
                              },
                              {
                                value: 2,
                                label: "Who is this Toucan?",
                                trigger: "4",
                              },
                              {
                                value: 3,
                                label: "What is Amak?",
                                trigger: "5",
                              },
                            ],
                          },
                          {
                            id: "3",
                            message:
                              "This application aims to provide foreign tourists access to personalized colombian travel planning features, enhancing their ability to tailor their travel plans to their preferences.",
                            trigger: "2",
                          },
                          {
                            id: "4",
                            message:
                              "I am Yaruk the toucan, nice to meet you! I will be your guide in this WebApp",
                            trigger: "2",
                          },
                          {
                            id: "5",
                            message:
                              "We specialize in curating exclusive tours and experiences, driven by a commitment to responsibly transform and positively impact destinations through the promotion of sustainable tourism.",
                            trigger: "2",
                          },
                        ]}
                      />
                    </div>
                  )}
                  {activeTab === "tab2" && (
                    <div>
                      {/* Content for Tab 2 */}
                      <VirtualAssistantChat
                        steps={[
                          {
                            id: "1",
                            message:
                              "What do you want to know about our plans?",
                            trigger: "2",
                          },
                          {
                            id: "2",
                            options: [
                              {
                                value: 1,
                                label: "How do I get plans quotes?",
                                trigger: "3",
                              },
                              {
                                value: 2,
                                label: "Can I expore other departments?",
                                trigger: "4",
                              },
                              {
                                value: 3,
                                label: "How do I access my added plans?",
                                trigger: "5",
                              },
                            ],
                          },
                          {
                            id: "3",
                            message:
                              "To get the quotes of a plan, start scrolling and the plans with their prices will be displayed for you to add them to your next adventure!",
                            trigger: "2",
                          },
                          {
                            id: "4",
                            message:
                              "In the future, you will be able to explore other colombian departments. By now, you will be exploring the magic of Valle del Cauca.",
                            trigger: "2",
                          },
                          {
                            id: "5",
                            message:
                              "You can access to your Added Plans by selecting the Profile section. Remember that you can create an account to save them.",
                            trigger: "2",
                          },
                        ]}
                      />
                    </div>
                  )}

                  {activeTab === "tab3" && (
                    <div>
                      {/* Content for Tab 3 */}
                      <VirtualAssistantChat
                        steps={[
                          {
                            id: "1",
                            message:
                              "What do you want to know about your profile?",
                            trigger: "2",
                          },
                          {
                            id: "2",
                            options: [
                              {
                                value: 1,
                                label: "How do I create an account?",
                                trigger: "3",
                              },
                              {
                                value: 2,
                                label: "How do I log into my account?",
                                trigger: "4",
                              },
                              {
                                value: 3,
                                label: "How to check my information?",
                                trigger: "5",
                              },
                            ],
                          },
                          {
                            id: "3",
                            message:
                              "You can create your account by selecting the profile image in this panel, then the option will show up.",
                            trigger: "2",
                          },
                          {
                            id: "4",
                            message:
                              "You can log into your account by selecting the profile image in this panel, then the option will show up.",
                            trigger: "2",
                          },
                          {
                            id: "5",
                            message:
                              "By selecting the profile image, you will be able to access your profile (if it’s already created), your added plans and the ones you already purchased",
                            trigger: "2",
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className='virtual-assistant-panel'>
                {/* Header with user information */}
                <div className='panel-header'>
                  {/* User icon */}
                  <div>
                    <img
                      src={publicUrl + "assets/svg/profile-icon.svg"}
                      alt='profile-icon'
                    />
                  </div>
                  <button
                    className='profile-manage-buttons-border'
                    onClick={toggleSignIn}
                  >
                    Sign In
                  </button>
                  <button
                    className='profile-manage-buttons'
                    onClick={toggleRegister}
                  >
                    Sign Up
                  </button>
                </div>
                {/* Tabs */}
                <div className='tab-container'>
                  {/* tab */}
                  <button
                    className={activeTab === "tab1" ? "active" : ""}
                    onClick={() => changeTab("tab1")}
                  >
                    Introduction
                  </button>
                  {/* tab */}
                  <button
                    className={activeTab === "tab2" ? "active" : ""}
                    onClick={() => changeTab("tab2")}
                  >
                    Plans
                  </button>
                  {/* tab */}
                  <button
                    className={activeTab === "tab3" ? "active" : ""}
                    onClick={() => changeTab("tab3")}
                  >
                    Profile
                  </button>
                  {/* Add more tabs as needed */}
                </div>

                {/* Content shown depending on the selected tab */}
                <div className='tab-content'>
                  {activeTab === "tab1" && (
                    <div>
                      {/* Content for Tab 1 */}
                      <VirtualAssistantChat
                        steps={[
                          {
                            id: "1",
                            message: "What are you interested in?",
                            trigger: "2",
                          },
                          {
                            id: "2",
                            options: [
                              {
                                value: 1,
                                label: "What is  this WebApp for?",
                                trigger: "3",
                              },
                              {
                                value: 2,
                                label: "Who is this Toucan?",
                                trigger: "4",
                              },
                              {
                                value: 3,
                                label: "What is Amak?",
                                trigger: "5",
                              },
                            ],
                          },
                          {
                            id: "3",
                            message:
                              "This application aims to provide foreign tourists access to personalized colombian travel planning features, enhancing their ability to tailor their travel plans to their preferences.",
                            trigger: "2",
                          },
                          {
                            id: "4",
                            message:
                              "I am Yaruk the toucan, nice to meet you! I will be your guide in this WebApp",
                            trigger: "2",
                          },
                          {
                            id: "5",
                            message:
                              "We specialize in curating exclusive tours and experiences, driven by a commitment to responsibly transform and positively impact destinations through the promotion of sustainable tourism.",
                            trigger: "2",
                          },
                        ]}
                      />
                    </div>
                  )}
                  {activeTab === "tab2" && (
                    <div>
                      {/* Content for Tab 2 */}
                      <VirtualAssistantChat
                        steps={[
                          {
                            id: "1",
                            message:
                              "What do you want to know about our plans?",
                            trigger: "2",
                          },
                          {
                            id: "2",
                            options: [
                              {
                                value: 1,
                                label: "How do I get plans quotes?",
                                trigger: "3",
                              },
                              {
                                value: 2,
                                label: "Can I expore other departments?",
                                trigger: "4",
                              },
                              {
                                value: 3,
                                label: "How do I access my added plans?",
                                trigger: "5",
                              },
                            ],
                          },
                          {
                            id: "3",
                            message:
                              "To get the quotes of a plan, start scrolling and the plans with their prices will be displayed for you to add them to your next adventure!",
                            trigger: "2",
                          },
                          {
                            id: "4",
                            message:
                              "In the future, you will be able to explore other colombian departments. By now, you will be exploring the magic of Valle del Cauca.",
                            trigger: "2",
                          },
                          {
                            id: "5",
                            message:
                              "You can access to your Added Plans by selecting the Profile section. Remember that you can create an account to save them.",
                            trigger: "2",
                          },
                        ]}
                      />
                    </div>
                  )}

                  {activeTab === "tab3" && (
                    <div>
                      {/* Content for Tab 3 */}
                      <VirtualAssistantChat
                        steps={[
                          {
                            id: "1",
                            message:
                              "What do you want to know about your profile?",
                            trigger: "2",
                          },
                          {
                            id: "2",
                            options: [
                              {
                                value: 1,
                                label: "How do I create an account?",
                                trigger: "3",
                              },
                              {
                                value: 2,
                                label: "How do I log into my account?",
                                trigger: "4",
                              },
                              {
                                value: 3,
                                label: "How to check my information?",
                                trigger: "5",
                              },
                            ],
                          },
                          {
                            id: "3",
                            message:
                              "You can create your account by selecting the profile image in this panel, then the option will show up.",
                            trigger: "2",
                          },
                          {
                            id: "4",
                            message:
                              "You can log into your account by selecting the profile image in this panel, then the option will show up.",
                            trigger: "2",
                          },
                          {
                            id: "5",
                            message:
                              "By selecting the profile image, you will be able to access your profile (if it’s already created), your added plans and the ones you already purchased",
                            trigger: "2",
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VirtualAssistant;
