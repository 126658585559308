import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const SignIn = ({ onCloseSignIn, signIn, onCloseRegister, register }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/login";

  let user = {
    email,
    password,
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(apiUrl, user)
      .then((res) => {
        let data = res.data.data;
        if (data.message === "ok") {
          Swal.fire({
            title: "Good job!",
            text: "You are logged",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              window.localStorage.setItem("token", data.token);
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text:
              "Something went wrong, check your data and try to sign in again",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong, check your data and try to sign in again",
        });
        console.error(error);
      });
  };

  return (
    <div className='signInOverlay'>
      <form className='loginForm'>
        <h3>Sign In!</h3>

        <input
          className='loginInput'
          type='text'
          placeholder='Email'
          id='username'
          onChange={(e) => setEmail(e.target.value)}
        />

        <input
          className='passInput'
          type='password'
          placeholder='Password'
          id='password'
          onChange={(e) => setPassword(e.target.value)}
        />

        <button className='loginButton' onClick={onSubmit}>
          SIGN IN
        </button>
        {/* In case we want to add external authentication */}
        {/* <div class='social'>
          <div className='googleIcon'>
            <img src={publicUrl + "assets/img/social.png"} />
          </div>
          <div class='fb'>
            <img src={publicUrl + "assets/img/facebook.png"} />
          </div>
        </div> */}
        <div className='login-text'>
          <p>Don't have an account?</p>
          <p
            onClick={() => {
              onCloseSignIn(!signIn);
              onCloseRegister(!register);
            }}
          >
            Sign Up
          </p>
        </div>
        <button className='closeButton' onClick={() => onCloseSignIn(!signIn)}>
          <img src={publicUrl + "assets/img/icons/close.svg"} alt='Close' />
        </button>
      </form>
    </div>
  );
};

export default SignIn;
