import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const SelectRole = ({
  name,
  phone,
  email,
  password,
  onCloseSelectRole,
  openSelectRole,
}) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [selectedRole, setSelectedRole] = useState([]);
  const [hasRole, setHasRole] = useState(false);
  const roleOptions = [
    { id: 2, imageUrl: "assets/img/Adventurer.png", text: "Adventure" },
    { id: 1, imageUrl: "assets/img/Hiker.png", text: "Hiking" },
    { id: 3, imageUrl: "assets/img/Culturizer.png", text: "Culture" },
  ];
  const apiUrl = process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/users";
  const apiUrlLogin =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/login";

  let user = {
    name: name,
    phone: phone,
    email: email,
    password: password,
    image: selectedRole ? selectedRole.imageUrl : "",
    role_id: selectedRole ? selectedRole.id : null,
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
    setHasRole(!hasRole);
  };

  const loginUser = async () => {
    await axios
      .post(apiUrlLogin, user)
      .then((res) => {
        let data = res.data.data;
        if (data.message === "ok") {
          window.localStorage.setItem("token", data.token);
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.error,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      });
  };

  const onSubmit = async () => {
    if (hasRole) {
      await axios
        .post(apiUrl, user)
        .then((res) => {
          let data = res.data;
          if (data.message === "ok") {
            Swal.fire({
              title: "Good job!",
              text: "User Registered",
              icon: "success",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                loginUser();
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: data.error,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error,
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Role not selected",
        text: "Select your role before signing up",
      });
      return;
    }
  };

  return (
    <div className='signInOverlay'>
      <div className='select-role-container'>
        <h3>Select Your Role!</h3>
        <div className='role-options'>
          {roleOptions.map((role) => (
            <div key={role.id}>
              <div
                className={`tp-img-upload ${
                  selectedRole && selectedRole.id === role.id ? "selected" : ""
                }`}
                onClick={() => handleRoleSelect(role)}
              >
                <div className='tp-avatar-preview'>
                  <div
                    id='tp_imagePreview'
                    style={{
                      backgroundImage: `url(${publicUrl}${role.imageUrl})`,
                    }}
                  ></div>
                  <p>{role.text}</p>
                </div>
              </div>
              {role.id === 1 && <div className='separator-line'></div>}
              {role.id === 2 && <div className='separator-line'></div>}
            </div>
          ))}
        </div>
        <p>
          Do you like extreme landscapes? Long walks in the forest? Or maybe
          enjoy a good dance show? Choose your role and earn 50% more points on
          the plans associated with your category.
        </p>
        <button className='registerButton' onClick={onSubmit}>
          SIGN UP
        </button>
        <button
          className='closeButton'
          onClick={() => {
            onCloseSelectRole(!openSelectRole);
          }}
        >
          <img src={publicUrl + "assets/img/icons/close.svg"} alt='Close' />
        </button>
      </div>
    </div>
  );
};

export default SelectRole;
