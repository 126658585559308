import React, { useEffect, useState } from "react";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/banner-v2";
import QuotationEngine from "./section-components/quotation-engine";
import PlanDescription from "./section-components/plan-description";
import LocationsInfo from "./section-components/locations-info";
import IncludedServices from "./section-components/included-services";
import AdditionalActivities from "./section-components/additional-activities";
import RelatedPlansCarousel from "./section-components/related-plans-carousel";
import VirtualAssistant from "./global-components/virtual-assistant";
import Footer from "./global-components/footer-v2";
import { fetchPlans } from "../api";

const PlanPage = () => {
  const [planInfo, setPlanInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [shouldReload, setShouldReload] = useState(false); // New state variable
  const pageType = "planpage";
  const publicUrl = process.env.PUBLIC_URL + "/";
  const [additionalActivities, setAdditionalActivities] = useState([]);
  const [cardData, setCardData] = useState("");
  const [tutorialText, setTutorialText] = useState(0);

  useEffect(() => {
    // Obtaining the PackageID from the localStorage
    const planId = localStorage.getItem("planId");

    // Retrieving the package based on the ID stored in localStorage
    async function fecthWoocommerceData() {
      try {
        const dataPackage = await fetchPlans().get(`/products/${planId}`);
        setPlanInfo(dataPackage.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fecthWoocommerceData();
  }, []);

  const handleCardClick = () => {
    setShouldReload(true);
  };

  const handleUpdatedCard = (data) => {
    setCardData(data);
  };

  const relatedIds = planInfo.related_ids;

  const handleSelectedActivitiesChange = (selectedActivities) => {
    setAdditionalActivities(selectedActivities);
  };

  const handleTutorialTextChange = (data) => {
    setTutorialText(data);
  };

  if (loading) {
    return (
      <div className='preloader' id='preloader'>
        <div className='preloader-inner'>
          <div className='spinner'>
            <div className='dot1'></div>
            <div className='dot2'></div>
          </div>
          <img src={`${publicUrl}assets/gif/toucan-gif.gif`} alt='' />
        </div>
      </div>
    );
  }

  return shouldReload ? (
    <PlanPage />
  ) : (
    <div>
      <Navbar pageType={pageType} indexToHighlight={tutorialText} />
      <Banner planInfo={planInfo} pageType={pageType} />
      <QuotationEngine
        planGeneralInfo={planInfo}
        additionalActivities={additionalActivities}
      />
      <AdditionalActivities
        planInfo={planInfo}
        onSelectedActivitiesChange={handleSelectedActivitiesChange}
      />
      <IncludedServices planInfo={planInfo} />
      <LocationsInfo planInfo={planInfo} onUpdatedCard={handleUpdatedCard} />
      <RelatedPlansCarousel
        relatedIds={relatedIds}
        pageType={pageType}
        onCardClick={handleCardClick}
      />
      <VirtualAssistant
        obtainedPoints={cardData}
        pageType={pageType}
        onTutorialTextChange={handleTutorialTextChange}
      />
      <Footer />
    </div>
  );
};

export default PlanPage;
