import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PlanCard from "./plan-card";

// Responsiveness breakpoints
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

// The component renders all of the retrieved plans by default. If the user filters, it renders only the filtered ones.
const PlansCarousel = ({ plans, filteredPlans }) => {
  return (
    <Carousel responsive={responsive}>
      {filteredPlans.length > 0
        ? filteredPlans.map((filteredPlan, filteredPlanIndex) => (
            <div key={filteredPlanIndex}>
              <PlanCard planInfo={filteredPlan} />
            </div>
          ))
        : plans.map((plan, planIndex) => (
            <div key={planIndex}>
              <PlanCard planInfo={plan} />
            </div>
          ))}
    </Carousel>
  );
};

export default PlansCarousel;
