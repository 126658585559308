import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class BannerPlan extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const colombiaMap = `${publicUrl}assets/svg/colombia-map.svg`;
    let imagealt = "image";

    return (
      <div
        className="main-banner-area jarallax"
        style={{
          backgroundImage: "url(" + publicUrl + "assets/img/bahia_malaga.png)",
        }}
      >
        {/* Colombia Map and Department Name*/}
        <div className="content">
          <div className="map-content">
            <h1 className="department-name">BAHIA MALAGA PLAN</h1>
            {/* <img className="img-map" src={colombiaMap} alt="" /> */}
          </div>
          <div className="container">
            <div className="scroll-down">
              <a href="#main_search" className="text-center">
                <span />
                <i className="la la-long-arrow-down" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BannerPlan;
