import React, { Component } from "react";

class PlanDescription extends Component {
  render() {
    let planInfo = this.props.planInfo;

    // Function used to convert HTML to plain text so it can be shown in the description
    const convertHtmlToPlainText = (html) => {
      const doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    };

    return (
      <div className='video-area tp-video-area pd-top-110'>
        <div className='container viaje-go-top'>
          <div className='row'>
            <div
              className='col-xl-5 col-lg-6 align-self-center wow animated fadeInRight'
              data-wow-duration='1s'
              data-wow-delay='0.3s'
            >
              <div className='section-title mb-lg-0 mb-4 text-center text-lg-left'>
                <h3 className='title text-white-access text-uppercase'>
                  What is included?
                </h3>
                <p>{convertHtmlToPlainText(planInfo.description)}</p>
              </div>
            </div>
            <div
              className='col-xl-5 col-lg-6 offset-xl-1 wow animated fadeInLeft'
              data-wow-duration='1s'
              data-wow-delay='0.3s'
            >
              <div className='video-popup-wrap'>
                <div className='thumb'>
                  {/* Consider adding a default image */}
                  <img src={planInfo.images[0].src} alt='plan-img' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlanDescription;
