import React from "react";

const AddedPlanCard = ({ quotation, onDelete }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className='d-flex flex-column align-items-center justify-content-center card mb-3 p-2'>
      {/* Card header */}
      <h6 className='text-black-access text-uppercase text-bold text-center'>
        {quotation.plan_name}
      </h6>
      <img
        src={quotation.image_url}
        alt='added-plan-img'
        className='w-75 rounded'
      />
      {/* Card header end */}

      <p className='text-gray-access text-bold'>
        *From <span className='text-bold'>{quotation.price}</span> USD
      </p>

      {/* Passengers */}
      <div className='d-flex align-items-center justify-content-center'>
        <img
          src={publicUrl + "assets/svg/passengers-icon.svg"}
          alt='passengers-icon'
          className='added-plan-passengers-icon mr-2'
        />
        <div className='text-bold text-gray-access'>
          {quotation.passengers}
          {quotation.passengers > 1 ? " passengers" : " passenger"}
        </div>
      </div>
      {/* Passengers end */}

      {/* Travelling dates */}
      <div className='mt-3'>
        <p className='text-black-access text-bold'>
          Departure Date:{" "}
          <span className='text-gray-access'>{quotation.departure_date}</span>
        </p>
        <p className='text-black-access text-bold'>
          Arrival Date:{" "}
          <span className='text-gray-access'>{quotation.arrival_date}</span>
        </p>
      </div>
      {/* Travelling dates end */}

      {/* Additional activities */}
      {quotation.additional_activities.length > 0 && (
        <div className='text-center mt-2'>
          <div className='d-flex align-items-center justify-content-center mb-2'>
            <img
              src={publicUrl + "assets/svg/additional-activities-icon.svg"}
              alt='additional-activities-icon'
              className='added-plan-passengers-icon mr-2'
            />
            <div className='text-black-access text-bold'>
              Additional Activities
            </div>
          </div>
          <ul
            className='text-gray-access text-bold'
            style={{ listStyle: "none" }}
          >
            {quotation.additional_activities.map((activity, activityIndex) => (
              <li key={activityIndex}>{activity}</li>
            ))}
          </ul>
        </div>
      )}
      {/* Additional activities end */}

      {/* Delete button */}
      <button
        onClick={onDelete}
        className='btn btn-yellow text-center added-plan-card-btn text-bold'
      >
        Delete
      </button>
      {/* Delete button end */}
    </div>
  );
};

export default AddedPlanCard;
