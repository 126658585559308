import React, { useState } from "react";
import CardLocationsInfo from "./card-locations-info";

const LocationsInfo = ({ planInfo, onUpdatedCard }) => {
  const planAttributes = planInfo.attributes;
  const hasPlanAttributes = planAttributes && planAttributes.length > 0;
  const [cardData, setCardData] = useState(null);
  const locationInformation = hasPlanAttributes
    ? planAttributes[planAttributes.length - 2].options
    : [];

  // Conditionally render the component
  if (!hasPlanAttributes) {
    return null;
  }

  const handlePointsChange = (data) => {
    setCardData(data);
    onUpdatedCard(cardData);
  };

  return (
    <div id='locations_info' className='upcomming-world pt-5 viaje-go-top'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-7 col-lg-8'>
            <div className='section-title'>
              <h3 className='title text-white-access text-uppercase'>
                Locations information
              </h3>
              <p>
                Find here some facts about the locations related to this plan.
                They also grant you points related to the category of the plan.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='upcomming-card-slider upcomming-card-slider-1 tp-common-slider-style'>
        {locationInformation.map((information, informationIndex) => {
          const [title, description, imgSrc, bgImgSrc] = information.split(
            " - "
          );
          return (
            <CardLocationsInfo
              key={informationIndex}
              title={title}
              description={description}
              imgSrc={imgSrc}
              bgImgSrc={bgImgSrc}
              category_id={planInfo.tags[0].id}
              onPointsChange={handlePointsChange}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LocationsInfo;
