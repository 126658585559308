import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const EditProfile = ({ onCloseEditProfile, editProfile }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [isOpen, setIsOpen] = useState(true);
  const [user, setUser] = useState([]);
  const [name, setName] = useState([]);
  const [phone, setPhone] = useState([]);
  const [email, setEmail] = useState([]);
  const apiUrl =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/token/";
  const apiUpdateUrl =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/users/";

  useEffect(() => {
    async function fetchData() {
      try {
        const actualToken = window.localStorage.getItem("token");
        if (actualToken != null) {
          const dataUser = await axios.get(apiUrl + actualToken);
          const actualUser = dataUser.data.data;
          setUser(actualUser);
          setName(actualUser.name);
          setEmail(actualUser.email);
          setPhone(actualUser.phone);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
    fetchData();
  }, []);

  const onSubmit = async () => {
    let newUser = {
      name,
      email,
      phone,
    };
    for (const prop in newUser) {
      if (newUser.hasOwnProperty(prop)) {
        if (newUser[prop] === "") {
          switch (prop) {
            case "name":
              newUser[prop] = user.name;
              break;
            case "email":
              newUser[prop] = user.email;
              break;
            case "phone":
              newUser[prop] = user.phone;
              break;
          }
        }
      }
    }
    await axios
      .patch(apiUpdateUrl + user.id, newUser)
      .then((res) => {
        let data = res.data;
        if (data.message === "ok") {
          Swal.fire({
            title: "Good job!",
            text: "User Updated",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.error,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      });
  };

  return (
    <div className='signInOverlay'>
      <form className='registerForm'>
        <h3>Edit Profile</h3>

        <input
          className='nameInput'
          type='text'
          placeholder={user.name}
          id='name'
          onChange={(e) => setName(e.target.value)}
        />

        <input
          className='phoneInput'
          type='text'
          placeholder={user.phone}
          id='phone'
          onChange={(e) => setPhone(e.target.value)}
        />

        <input
          className='registerEmailInput'
          type='text'
          placeholder={user.email}
          id='email'
          onChange={(e) => setEmail(e.target.value)}
        />

        <button type='submit' className='registerButton' onClick={onSubmit}>
          SAVE
        </button>
        <button
          className='closeButton'
          onClick={() => {
            onCloseEditProfile(!editProfile);
          }}
        >
          <img src={publicUrl + "assets/img/icons/close.svg"} alt='Close' />
        </button>
      </form>
    </div>
  );
};

export default EditProfile;
