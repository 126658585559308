import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class UserProfile extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="user-profile-area pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12">
              <div className="row profile-information">
                <div className="profile-section">
                  <h3>Profile</h3>
                  <div className="tp-img-upload main-profile-general">
                    <div className="tp-avatar-preview main-profile-pic">
                      <div id="	" className="main-profile-container">
                        <img src={publicUrl + "assets/img/Adventurer.png"} />
                      </div>
                    </div>
                  </div>
                  <h3>Nick Smith</h3>
                  <p>Adventurer</p>
                  <div className="select-role-section">
                    <h3>Select Your Role!</h3>
                    <div className="role-options">
                      <div className="tp-img-upload">
                        <div className="tp-avatar-preview">
                          <div
                            id="tp_imagePreview"
                            style={{
                              backgroundImage:
                                "url(" +
                                publicUrl +
                                "assets/img/Adventurer.png)",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="separator-line"></div>
                      <div className="tp-img-upload">
                        <div className="tp-avatar-preview">
                          <div
                            id="tp_imagePreview"
                            style={{
                              backgroundImage:
                                "url(" + publicUrl + "assets/img/Hiker.png)",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="separator-line"></div>
                      <div className="tp-img-upload">
                        <div className="tp-avatar-preview">
                          <div
                            id="tp_imagePreview"
                            style={{
                              backgroundImage:
                                "url(" +
                                publicUrl +
                                "assets/img/Culturizer.png)",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="user-details">
                  <h3 className="user-details-title">Basic Information</h3>
                  <form className="tp-form-wrap">
                    <div className="edit-inputs">
                      <div className="user-info-container">
                        <label className="user-info-label">
                          <span className="input-icon user-icon"></span>
                          <input type="text" name="name" placeholder="Name" />
                        </label>
                      </div>

                      <div className="user-info-container">
                        <label className="user-info-label">
                          <i className="fa fa-map-marker" />
                          <input type="tel" name="phone" placeholder="Phone" />
                        </label>
                      </div>

                      <div className="user-info-container">
                        <label className="user-info-label">
                          <span className="input-icon email-icon"></span>
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                          />
                        </label>
                      </div>

                      <div className="user-info-container">
                        <label className="user-info-label">
                          <span className="input-icon password-icon"></span>
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                          />
                        </label>
                      </div>

                      <div className="col-12">
                        <input
                          className="btn btn-yellow mt-3 text-center"
                          type="submit"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfile;
