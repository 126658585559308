import React from "react";

const IncludedServices = ({ planInfo }) => {
  const publicUrl = process.env.PUBLIC_URL + "/";
  const planAttributes = planInfo.attributes;
  const hasPlanAttributes = planAttributes && planAttributes.length > 0;
  const notIncludedServices = hasPlanAttributes
    ? planAttributes[planAttributes.length - 1].options
    : [];

  // Conditionally render the component
  if (!hasPlanAttributes) {
    return null;
  }

  return (
    <div id='included_services' className='intro-area pd-top-75'>
      <div className='container'>
        {/* Included services */}
        <div className='d-flex align-items-center justify-content-start mt-4 mb-5'>
          <h3 className='text-white-access mr-4'>Included Per Person</h3>
          <img
            src={publicUrl + "assets/svg/included-icon.svg"}
            alt='included-services-icon'
          />
        </div>
        <div className='row'>
          {/* Included service cards */}
          {planAttributes
            .filter((planAttribute) => !planAttribute.name.includes("Not"))
            .map((planAttribute, planAttributeIndex) => (
              <div
                key={planAttributeIndex}
                className='col-lg-3 col-sm-6 single-intro-two bl-0'
              >
                <div className='single-intro style-two'>
                  <div className='thumb'>
                    <img
                      src={planAttribute.options[1]}
                      alt='included-service-icon'
                    />
                  </div>
                  <h4 className='intro-title'>{planAttribute.name}</h4>
                  <p>{planAttribute.options[0]}</p>
                </div>
              </div>
            ))}
          {/* Included service cards end */}
        </div>
      </div>

      <div className='container'>
        {/* Not included services */}
        <div className='d-flex align-items-center justify-content-start mt-4 mb-5'>
          <h3 className='text-white-access mr-4'>Not Included</h3>
          <img
            src={publicUrl + "assets/svg/not-included-icon.svg"}
            alt='not-included-services-icon'
          />
        </div>
        <div className='row'>
          {/* Not included service cards */}
          {notIncludedServices.map(
            (notIncludedService, notIncludedServiceIndex) => (
              <div
                key={notIncludedServiceIndex}
                className='col-lg-3 col-sm-6 single-intro-two bl-0'
              >
                <div className='single-intro style-two'>
                  <h4 className='intro-title'>{notIncludedService}</h4>
                </div>
              </div>
            )
          )}
          {/* Not included service cards end */}
        </div>
      </div>
    </div>
  );
};

export default IncludedServices;
