import React, { useState } from "react";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/banner-v2";
import Package from "./section-components/package";
import Review from "./section-components/review";
import Footer from "./global-components/footer-v2";
import VirtualAssistant from "./global-components/virtual-assistant";

const Home_V3 = () => {
  const pageType = "homepage";
  const [navbarId, setNavbarId] = useState("");
  const [tutorialText, setTutorialText] = useState(0);

  // This function is used to receive the selected item from the navbar
  const handleNavbarId = (navbarId) => {
    setNavbarId(navbarId);
  };

  const handleTutorialTextChange = (data) => {
    setTutorialText(data);
  };

  return (
    <div>
      <Navbar
        pageType={pageType}
        sendNavbarSelection={handleNavbarId}
        indexToHighlight={tutorialText}
      />
      <Banner pageType={pageType} />
      <Package />
      <Review />
      <VirtualAssistant
        pageType={pageType}
        navbarId={navbarId}
        onTutorialTextChange={handleTutorialTextChange}
      />
      <Footer />
    </div>
  );
};

export default Home_V3;
