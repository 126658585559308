import React, { Component } from 'react';

class PageHeader extends Component {

    render() {

        let HeaderTitle = this.props.headertitle;
        let publicUrl = process.env.PUBLIC_URL+'/'
        let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle

        return (
             <div className="breadcrumb-area jarallax mb-4" style={{backgroundImage: 'url('+publicUrl+'assets/img/quotated-plans-bg.jpg)'}}>
				  <div className="container">
				    <div className="row">
				      <div className="col-lg-12">
				        <div className="breadcrumb-inner">
				          <h1 className="page-title text-uppercase">Quoted Plans</h1>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>


        )
    }
}


export default PageHeader