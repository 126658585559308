import React, { useState } from "react";
import CardAdditionalActivity from "./card-additional-activity";
import QuotationEngine from "./quotation-engine";

const AdditionalActivities = ({ planInfo, onSelectedActivitiesChange }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const planAttributes = planInfo.attributes;
  const hasPlanAttributes = planAttributes && planAttributes.length > 0;
  const additionalActivities = hasPlanAttributes
    ? planAttributes[planAttributes.length - 3].options
    : [];
  const [selectedActivities, setSelectedActivities] = useState([]);

  const handleCheckboxChange = (additionalActivity, isChecked) => {
    if (isChecked) {
      // If checked, add the additionalActivity to the selectedActivities array
      setSelectedActivities((prevSelectedActivities) => [
        ...prevSelectedActivities,
        additionalActivity,
      ]);
    } else {
      // If unchecked, remove the additionalActivity from the selectedActivities array
      setSelectedActivities((prevSelectedActivities) =>
        prevSelectedActivities.filter(
          (activity) => activity !== additionalActivity
        )
      );
    }
  };

  // Pass the selectedActivities array to the parent component
  onSelectedActivitiesChange(selectedActivities);

  // Conditionally render the component
  if (!hasPlanAttributes) {
    return null;
  }

  return (
    <div
      id='additional_activities'
      className='upcomming-tour upcomming-tour-bg pd-top-75 pd-bottom-120 viaje-go-top'
      style={{
        backgroundImage: "url(" + publicUrl + "assets/img/bg/11.png)",
      }}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='section-title'>
              <h3 className='text-uppercase title text-white-access'>
                Additional Activities
              </h3>
            </div>
            <div className='row'>
              <div className='col-lg-8'></div>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className='upcomming-card-slider upcomming-card-slider-2 tp-common-slider-style'>
              {/* Card */}
              {additionalActivities.map(
                (additionalActivity, additionalActivityIndex) => (
                  <CardAdditionalActivity
                    key={additionalActivityIndex}
                    additionalActivity={additionalActivity}
                    index={additionalActivityIndex}
                    onCheckboxChange={handleCheckboxChange}
                  />
                )
              )}
              {/* Card end */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalActivities;
