import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PlanCard from "./plan-card";
import { fetchPlans } from "../../api";

// Responsiveness breakpoints
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const RelatedPlansCarousel = ({ relatedIds, pageType, onCardClick }) => {
  const [relatedPlans, setRelatedPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const publicUrl = process.env.PUBLIC_URL + "/";
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    setIsMounted(true);

    async function fetchWoocommerceData() {
      try {
        const fetchPromises = relatedIds.map(async (relatedId) => {
          const plansData = await fetchPlans().get(`/products/${relatedId}`);
          return plansData.data;
        });

        const relatedPlansArray = await Promise.all(fetchPromises);
        setRelatedPlans(relatedPlansArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    fetchWoocommerceData();

    return () => {
      setIsMounted(false); // Set isMounted to false when the component unmounts
    };
  }, [relatedIds]);

  if (!relatedIds) {
    return (
      <div id='related_plans' className='package-area pd-top-108 mg-bottom-92'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-6 col-lg-8'>
              <div className='section-title text-center'>
                <h3
                  className='text-uppercase text-white-access title wow animated fadeInUp'
                  data-wow-duration='0.6s'
                  data-wow-delay='0.1s'
                >
                  Related plans!
                </h3>
              </div>
            </div>
          </div>
          <p className='text-white-access'>
            There are no related plans to show here{" "}
            <span role='img' aria-label='sad-emoji'>
              ☹
            </span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div id='related_plans' className='package-area pd-top-108 mg-bottom-92'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-6 col-lg-8'>
            <div className='section-title text-center'>
              <h3
                className='text-uppercase text-white-access title wow animated fadeInUp'
                data-wow-duration='0.6s'
                data-wow-delay='0.1s'
              >
                Related plans!
              </h3>
            </div>
          </div>
        </div>

        {loading ? (
          <div className='d-flex justify-content-center'>
            <img
              src={publicUrl + "assets/svg/loading-spinner.svg"}
              alt='logo'
            />
          </div>
        ) : (
          <Carousel responsive={responsive}>
            {relatedPlans.map((relatedPlan, relatedPlanIndex) => (
              <div key={relatedPlanIndex}>
                <PlanCard
                  planInfo={relatedPlan}
                  pageType={pageType}
                  onCardClick={onCardClick}
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default RelatedPlansCarousel;
