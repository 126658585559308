import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const QuotatePlan = ({ isOpenQuotate, toggleQuotatePlan }) => {
  const publicUrl = process.env.PUBLIC_URL + "/";
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const apiUrl =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/quotations";

  const sendQuotationWithoutAccount = async (e) => {
    e.preventDefault();
    if (name === "" || phone === "") {
      Swal.fire({
        icon: "error",
        title: "Empty fields",
        text: "Check your information before sending it",
      });
    } else {
      // Send the user's name and phone to every quotation object
      let quotations = JSON.parse(localStorage.getItem("quotations"));
      const quotationsToSend = quotations.map((quotation) => {
        quotation.user_name = name;
        quotation.user_phone = phone;
        quotation.quotation_date = new Date().toLocaleDateString();
        return quotation;
      });

      await axios
        .post(apiUrl, quotationsToSend)
        .then((res) => {
          let data = res.data;
          if (data.message === "Quotations successfully inserted") {
            Swal.fire({
              icon: "success",
              title: "The quotation has been successfully sent!",
            }).then((result) => {
              if (result.isConfirmed) {
                window.localStorage.removeItem("quotations");
                window.location.reload();
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "There has been an error with your quotation",
              text: "Try to quotate it again",
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "There has been a network error",
            text: "Try again",
          });
        });
    }
  };

  return (
    <div>
      {isOpenQuotate && (
        <div className='signInOverlay'>
          <form className='loginForm'>
            <h3>Quotate Your Plan!</h3>

            <input
              className='nameInput'
              type='text'
              placeholder='Name'
              id='username'
              onChange={(e) => setName(e.target.value)}
            />

            <input
              className='phoneInput'
              type='text'
              placeholder='Phone Number'
              id='phone'
              onChange={(e) => setPhone(e.target.value)}
            />

            <div className='login-text text-center'>
              <p>An Amak agent will you contact you soon!</p>
            </div>

            <button
              className='registerButton'
              type='submit'
              onClick={sendQuotationWithoutAccount}
            >
              SEND QUOTATION
            </button>
            <div className='login-text text-center'>
              <p>
                Enhance your Amak Experience! Create an account to save your
                quotations, points, and medals.
              </p>
            </div>
            {/* In case we want to use external authentication */}
            {/* <div className="registerSocial">
              <div className="googleIcon">
                <img
                  src={publicUrl + "assets/img/social.png"}
                  alt="social-icon"
                />
              </div>
              <div className="fb">
                <img
                  src={publicUrl + "assets/img/facebook.png"}
                  alt="facebook-icon"
                />
              </div>
            </div> */}
            <button className='closeButton' onClick={() => toggleQuotatePlan()}>
              <img src={publicUrl + "assets/img/icons/close.svg"} alt='Close' />
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default QuotatePlan;
