import React, { useEffect, useState } from "react";
import SideBar from "../global-components/side-bar";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import Swal from "sweetalert2";
import axios from "axios";

const QuotationEngine = ({ planGeneralInfo, additionalActivities }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [quotations, setQuotations] = useState([]);
  const [passengerCount, setPassengerCount] = useState(1);
  const [departureDate, setDepartureDate] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isLogged, setIsLogged] = useState(false);
  const apiUrl =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/token/";

  useEffect(() => {
    // This function is used to retrieve the current user ID
    async function fetchUserData() {
      try {
        const currentToken = localStorage.getItem("token");
        if (currentToken != null) {
          const userData = await axios.get(apiUrl + currentToken);
          const currentUserId = userData.data.data.id;
          setUserId(currentUserId);
          setIsLogged(true);
        }
      } catch (error) {
        console.error("Error fetching user ID:", error.response.data);
      }
    }

    fetchUserData();
  }, []);

  // Flatpickr config.
  const dateConfig = {
    minDate: "today",
    dateFormat: "Y-m-d",
  };

  // Qoutation object to be sent to the quotation modal.
  const quotationEngineInfo = {
    passengerCount: passengerCount,
    departureDate: departureDate
      ? getFormattedDate(new Date(departureDate))
      : "",
    arrivalDate: arrivalDate ? getFormattedDate(new Date(arrivalDate)) : "",
  };

  // Functions.
  // Funtion used to format the selected dates before sending them.
  function getFormattedDate(date) {
    if (!date) {
      return null; // Handle case where the date is empty
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Month is 0-indexed
    const day = date.getDate();

    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;

    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  // Both handleSubtract and handleAdd are used for the passengers counter functionality.
  const handleSubtract = () => {
    if (passengerCount > 1) {
      setPassengerCount(passengerCount - 1);
    }
  };

  const handleAdd = () => {
    setPassengerCount(passengerCount + 1);
  };

  // This function is used to check if are there any empty values inside the quotation object before it is sent to the quotation modal.
  const hasEmptyFields = (obj) =>
    Object.values(obj).some((value) => value === "");

  // This function is used to add quotations to the quotations array.
  const addItemToQuotations = () => {
    const newItem = {
      plan_name: planGeneralInfo.name,
      passengers: quotationEngineInfo.passengerCount,
      price: planGeneralInfo.price,
      departure_date: quotationEngineInfo.departureDate,
      arrival_date: quotationEngineInfo.arrivalDate,
      category_id: planGeneralInfo.tags[0].id,
      image_url: planGeneralInfo.images[0].src,
      additional_activities: additionalActivities,
      user_id: userId,
    };

    // An identifier is created for the new item that has been added.
    const newItemIdentifier = JSON.stringify(newItem);

    // It is checked if the item already exists.
    const itemAlreadyExists = quotations.some(
      (item) => JSON.stringify(item) === newItemIdentifier
    );

    if (!itemAlreadyExists) {
      // Retrieve existing quotations from localStorage
      const existingQuotationsString = localStorage.getItem("quotations");
      const existingQuotations = existingQuotationsString
        ? JSON.parse(existingQuotationsString)
        : [];

      if (newItem.additional_activities.length === 0) {
        Swal.fire({
          icon: "question",
          title: "Do you want to continue without additional activities?",
          showDenyButton: true,
          confirmButtonText: "Continue",
          denyButtonText: `Go back`,
        }).then((result) => {
          if (result.isConfirmed) {
            // Add the new item to the array
            const updatedQuotations = [...existingQuotations, newItem];

            // Update state and localStorage
            setQuotations(updatedQuotations);
            localStorage.setItem(
              "quotations",
              JSON.stringify(updatedQuotations)
            );

            toggleOpenSidebar();

            Swal.fire({
              icon: "success",
              title: "The plan has been added succesfully!",
            });
          } else if (result.isDenied) {
            return;
          }
        });
      } else {
        // Add the new item to the array
        const updatedQuotations = [...existingQuotations, newItem];

        // Update state and localStorage
        setQuotations(updatedQuotations);
        localStorage.setItem("quotations", JSON.stringify(updatedQuotations));

        toggleOpenSidebar();
      }
    } else {
      isOpenSidebar && toggleOpenSidebar();
      // setQuotations
      Swal.fire({
        icon: "error",
        title: "You already added this quotation",
        text: "Check the information and try again",
      });
    }
  };

  // This function is used to toggle the state of the added plans sidebar.
  const toggleOpenSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar);
  };

  // This function is used to open the sidebar and send the quotation information if the quotation object is not empty.
  const sendQuotationInfo = () => {
    if (hasEmptyFields(quotationEngineInfo)) {
      Swal.fire({
        icon: "error",
        title: "Your quotation has empty fields",
        text: "Check the information and try again",
      });
    } else {
      addItemToQuotations();
    }
  };

  return (
    <div>
      <SideBar
        isOpenSidebar={isOpenSidebar}
        toggleOpenSidebar={toggleOpenSidebar}
      />
      <div
        className="search-area tp-main-search-area mt-0 pd-top-120 viaje-go-top tp-video-area"
        id="quotation_eng"
        style={{ backgroundImage: "url(" + publicUrl + "assets/img/bg/7.png)" }}
      >
        <div className="container">
          <h2 className="text-white-access text-uppercase text-center font-weight-bold">
            Quotate here!
          </h2>
          <div className="tp-main-search tp-main-search-2">
            <div className="d-flex align-items-center justify-content-center">
              {/* Calendar */}
              <div className="tp-search-single-wrap float-left col-lg-2 col-md-8 order-lg-6 mx-5">
                <div className="w-50 float-left">
                  <Flatpickr
                    value={departureDate}
                    onChange={([selectedDate]) => {
                      setDepartureDate(selectedDate);
                    }}
                    options={dateConfig}
                    placeholder="Departing"
                  />
                </div>
                <div className="dates-separator"></div>
                <i className="fa fa-calendar-minus-o" />
                <div className="tp-search-date w-50 float-left">
                  <Flatpickr
                    value={arrivalDate}
                    onChange={([selectedDate]) => {
                      setArrivalDate(selectedDate);
                    }}
                    options={dateConfig}
                    placeholder="Returning"
                  />
                </div>
              </div>
              {/* Calendar end */}
              {/* Passengers counter */}
              <div className="passenger-counter col-lg-2 col-md-6">
                <button className="counter-button" onClick={handleSubtract}>
                  -
                </button>
                <span className="passenger-count">{passengerCount}</span>
                <button className="counter-button" onClick={handleAdd}>
                  +
                </button>
                <div className="passenger-label">Passengers</div>
              </div>
              {/* Passengers counter end */}
              {/* Add button */}
              <div
                className="col-lg-2 col-md-4 order-12 btn btn-yellow"
                onClick={sendQuotationInfo}
              >
                Add this plan
              </div>
              {/* Add button end */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotationEngine;
