import React, { useState } from "react";

const CardAdditionalActivity = ({
  additionalActivity,
  index,
  onCheckboxChange,
}) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [isChecked, setChecked] = useState(false);
  const checkboxId = `custom-checkbox-${index}`;

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setChecked(newCheckedState);

    // Pass the additionalActivity and isChecked state to the parent
    onCheckboxChange(additionalActivity, newCheckedState);
  };

  return (
    <div>
      <div className='single-upconing-card'>
        <div
          className='shadow'
          style={{
            backgroundImage:
              "url(" +
              publicUrl +
              "assets/img/additional-activities-image.jpg)",
          }}
        >
          <img src={publicUrl + "assets/img/tour/9.png"} alt='img' />
        </div>
        <div className='tp-price-meta'>
          <input
            className='additional-activities-checkbox'
            type='checkbox'
            id={checkboxId}
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label htmlFor={checkboxId} className='checkbox-label text-uppercase'>
            <span className='custom-checkbox'></span>
            Add
          </label>
        </div>
        <div className='details'>
          <h3 className='title text-uppercase'>{additionalActivity}</h3>
        </div>
      </div>
    </div>
  );
};

export default CardAdditionalActivity;
