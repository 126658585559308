import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideBar from "../global-components/side-bar";

const Navbar = ({ pageType, sendNavbarSelection, indexToHighlight }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "logo";
  const [isLogged, setIsLogged] = useState(false);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  useEffect(() => {
    // This function is used to retrieve the current user ID
    async function fetchUserData() {
      try {
        const currentToken = localStorage.getItem("token");
        if (currentToken != null) {
          setIsLogged(true);
        }
      } catch (error) {
        console.error("Error fetching user ID:", error.response.data);
      }
    }

    fetchUserData();
  }, []);

  useEffect(() => {
    const homeNavbarItems = document.querySelectorAll(".home-navbar-item");
    const planNavbarItems = document.querySelectorAll(".plan-navbar-item");
    let navbarItems =
      pageType === "homepage" ? homeNavbarItems : planNavbarItems;
    const classToToggle = "tutorial-item-to-highlight";

    navbarItems.forEach((navbarItem, navbarItemIndex) => {
      if (pageType === "homepage" && navbarItemIndex < 1) {
        navbarItem.classList.remove(classToToggle);
        return;
      }

      if (navbarItemIndex === indexToHighlight) {
        navbarItem.classList.add(classToToggle);
      } else {
        navbarItem.classList.remove(classToToggle);
      }
    });
  }, [pageType, indexToHighlight]);

  const toggleOpenSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar);
  };

  // This function is used to send the selected item from the navbar to the home component
  const handleNavbarSelection = (selection) => {
    sendNavbarSelection(selection);
  };

  return (
    <div>
      <SideBar
        isOpenSidebar={isOpenSidebar}
        toggleOpenSidebar={toggleOpenSidebar}
      />
      <nav className='navbar navbar-area navbar-expand-lg nav-style-01 viaje-go-top'>
        <div className='container nav-container'>
          <div className='responsive-mobile-menu'>
            <div className='mobile-logo'>
              <Link to='/'>
                <img
                  src={publicUrl + "assets/img/sticky-logo.png"}
                  alt={imgattr}
                />
              </Link>
            </div>
            <button
              className='navbar-toggler float-right'
              type='button'
              data-toggle='collapse'
              data-target='#tp_main_menu'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggle-icon'>
                <span className='line' />
                <span className='line' />
                <span className='line' />
              </span>
            </button>
            <div className='nav-right-content'>
              <ul className='pl-0'>
                <li className='top-bar-btn-booking'>
                  <Link className='btn btn-yellow' to='/tour-details'>
                    Book Now <i className='fa fa-paper-plane' />
                  </Link>
                </li>
                <li className='tp-lang'>
                  <div className='tp-lang-wrap'>
                    <select className='select single-select'>
                      <option value={1}>ENG</option>
                      <option value={2}>BAN</option>
                      <option value={3}>Chinese</option>
                      <option value={3}>Spanish</option>
                    </select>
                  </div>
                </li>
                <li className='search'>
                  <i className='ti-search' />
                </li>
                <li className='notification'>
                  <a className='signUp-btn' href='#'>
                    <i className='fa fa-user-o' />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='collapse navbar-collapse' id='tp_main_menu'>
            <div className='logo-wrapper desktop-logo'>
              <Link to='/' className='main-logo'>
                <img src={publicUrl + "assets/img/amak-logo.png"} alt='logo' />
              </Link>
              <Link to='/' className='sticky-logo'>
                <img src={publicUrl + "assets/img/amak-logo.png"} alt='logo' />
              </Link>
            </div>
          </div>
          <div className='nav-right-content'>
            <ul className='navbar-nav'>
              <li
                className={
                  pageType === "planpage"
                    ? "disabled-item home-navbar-item"
                    : "home-navbar-item"
                }
              >
                <Link to='/'>Home</Link>
              </li>
              <li
                className={
                  pageType === "planpage"
                    ? "disabled-item scroll-down home-navbar-item"
                    : "scroll-down home-navbar-item"
                }
                onClick={() => handleNavbarSelection("plans")}
              >
                <a
                  href='#plan_selection'
                  className={pageType === "planpage" ? "text-green-access" : ""}
                >
                  Plans
                </a>
              </li>
              <li
                onClick={() => handleNavbarSelection("about")}
                className={
                  pageType === "planpage"
                    ? "disabled-item scroll-down home-navbar-item"
                    : "scroll-down home-navbar-item"
                }
              >
                <a href='#about_amak'>About</a>
              </li>
              <li
                onClick={() => handleNavbarSelection("quoted-plans")}
                className={
                  !isLogged
                    ? "disabled-item home-navbar-item"
                    : "home-navbar-item"
                }
              >
                <Link
                  to='/quotated-plans-page'
                  className={
                    pageType === "quotatedplans" ? "text-green-access" : ""
                  }
                >
                  Quoted Plans
                </Link>
              </li>
              <li className='scroll-down' onClick={toggleOpenSidebar}>
                <img
                  src={publicUrl + "assets/img/icons/cart.svg"}
                  alt='cart-icon'
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Conditional rendering for the sub-navbar based on the page type */}
      <div className='sub-nabvar navbar navbar-area navbar-expand-lg nav-style-01 viaje-go-top'>
        <div className=''>
          <div className='container nav-container'>
            {/* Mobile menu */}
            <div className='responsive-mobile-menu'></div>
            {/* Mobile menu end */}
            {/* Desktop menu */}
            <div className='collapse navbar-collapse nav-right-content'>
              <ul className='navbar-nav'>
                {pageType === "planpage" && (
                  // Plans sub-navbar
                  <div>
                    <li className='scroll-down'>
                      <a href='#quotation_eng'>Qoutate Plan</a>
                    </li>
                    <li className='scroll-down plan-navbar-item'>
                      <a href='#additional_activities'>Add Activities</a>
                    </li>
                    <li className='scroll-down plan-navbar-item'>
                      <a href='#included_services'>Services</a>
                    </li>
                    <li className='scroll-down plan-navbar-item'>
                      <a href='#locations_info'>Locations Info</a>
                    </li>
                    <li className='scroll-down plan-navbar-item'>
                      <a href='#related_plans'>Related Plans</a>
                    </li>
                  </div>
                )}
              </ul>
            </div>
            {/* Desktop menu end */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
