import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Review extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div id="about_amak" className="client-review-area client-review-area-home pd-top-70 pd-bottom-70">
        <div className="container card-styles">
          <div className="row p-4">
            {/* Start Image Area */}
            <div className="col-lg-4 align-self-center">
              <div
                className="thumb wow animated fadeInRight"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <div className="client-review-thumb">
                  <img
                    src={publicUrl + "assets/img/others/image34.png"}
                    alt="review"
                  />
                </div>
              </div>
            </div>
            {/* Finish Image Area */}

            {/* Start Text Area */}
            <div className="col-lg-8">
              <div
                className="single-client-review wow animated fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <h3 className="location-name">Get to know Amak!</h3>
                <p>
                  We are a 100% Colombian travel agency that assists Colombian
                  and foreign tourists in designing their trips, which include
                  exclusive tours and experiences. One of our main pillars is
                  the responsibility to transform and positively impact
                  destinations, promoting sustainable tourism.
                </p>
                <div className="media">
                  <div className="media-left">
                    <img
                      src={publicUrl + "assets/img/toucan-img/toucan-amak.png"}
                      alt="client"
                    />
                  </div>
                  <div className="media-body">
                    <h6>Yaruk</h6>
                    <p>
                      You already know me, I’m a colombian toucan that will
                      guide throught your experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Finish Text Area */}
          </div>
        </div>
      </div>
    );
  }
}

export default Review;
