import React, { useEffect, useState } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import QuotatedPlanCard from "./section-components/quotated-plan-card";
import axios from "axios";

const QuotatedPlansPage = () => {
  const pageType = "quotatedplans";
  const tokenApiUrl =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/token/";
  const quotationsApiUrl =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/quotations/";
  const [userId, setUserId] = useState(null);
  const [quotationData, setQuotationData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const currentToken = localStorage.getItem("token");
        if (currentToken != null) {
          // Fetch user data
          const userData = await axios.get(tokenApiUrl + currentToken);
          const currentUserId = userData.data.data.id;
          setUserId(currentUserId);

          // Fetch quotation data
          const quotationsData = await axios.get(
            quotationsApiUrl + currentUserId
          );
          const currentQuotationData = quotationsData.data;
          setQuotationData(currentQuotationData);
        }
      } catch (error) {
        console.error("Error fetching data:", error.response.data);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      {/* Modify conditionally the navbar so the user is not redirected incorrectly to other pages */}
      <Navbar pageType={pageType}></Navbar>
      <PageHeader />
      {quotationData ? (
        <div className='d-flex flex-column align-items-center p-4'>
          <h3 className='text-white-access mb-4'>
            You have quoted {quotationData.length}{" "}
            {quotationData.length > 1 ? "plans" : "plan"}
          </h3>
          {quotationData.map((quotation, quotatioKey) => (
            <QuotatedPlanCard quotation={quotation} key={quotatioKey} />
          ))}
        </div>
      ) : (
        <div className='d-flex flex-column align-items-center p-4'>
          <h3 className='text-white-access text-bold mb-4'>
            You haven't quotated plans yet{" "}
            <span role='img' aria-label='sad-emoji'>
              🙁
            </span>
          </h3>
          <h4 className='text-white-access'>
            Start quotating by selecting your preferred plans!
          </h4>
        </div>
      )}
    </div>
  );
};

export default QuotatedPlansPage;
