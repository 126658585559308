import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";

const CardLocationsInfo = ({
  title,
  description,
  imgSrc,
  bgImgSrc,
  category_id,
  onPointsChange,
}) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const apiUrl =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/token/";
  const apiUrlCategories =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/categories/";
  const apiUrlPoints =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/points/";
  const apiUrlCreatePoints =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/points";
  const apiUrlCreateMedals =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/medals";
  const [user, setUser] = useState([]);
  const [category, setCategory] = useState([]);
  const [points, setPoints] = useState(30);
  const medalOptions = [
    {
      id: 1,
      description: "Medal for users with 90 Hiking points",
      name: "Hiking Medal",
      category: "Hiking",
    },
    {
      id: 2,
      description: "Medal for users with 90 Adventure points",
      name: "Adventure Medal",
      category: "Adventure",
    },
    {
      id: 3,
      description: "Medal for users with 90 Culture points",
      name: "Culture Medal",
      category: "Culture",
    },
  ];

  const clickCard = async (e) => {
    e.preventDefault();
    try {
      const userToken = window.localStorage.getItem("token");
      if (userToken != null) {
        const userPoints = await axios.get(apiUrlPoints + user.id);
        const actualPoints = userPoints.data;
        const puntajeEncontrado = actualPoints.find(
          (puntaje) => puntaje.category.id === category.id
        );
        if (puntajeEncontrado) {
          updatePoints(puntajeEncontrado);
        } else {
          createPoints();
        }
      } else {
        Swal.fire({
          title: "Do you want to obtain points?",
          text: "Create an account!",
          icon: "question",
        });
      }
    } catch (error) {
      if (error.response.data.message === "Point not found") {
        const userToken = window.localStorage.getItem("token");
        if (userToken != null) {
          createPoints();
        } else {
          Swal.fire({
            title: "Do you want to obtain points?",
            text: "Create an account!",
            icon: "question",
          });
        }
      }
      console.log("Error fetching points", error);
    }
  };

  const createPoints = async () => {
    let newPoints = points;
    if (user.role.category.woocommerce_id === category_id) {
      newPoints = points * 1.5;
    }
    let point = {
      amount: newPoints,
      user_id: user.id,
      category_id: category.id,
    };
    try {
      await axios
        .post(apiUrlCreatePoints, point)
        .then((res) => {
          let data = res.data;
          if (data.message === "ok") {
            setPoints(newPoints); // Updating points in state
            onPointsChange(newPoints);
            Swal.fire({
              title: "Good job!",
              text:
                "You got " +
                newPoints +
                " " +
                category.name +
                " points! Check your profile",
              icon: "success",
              confirmButtonText: "Ok",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong with your points: " + data.error,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const updatePoints = async (puntajeEncontrado) => {
    let newPoints = points;
    let reachTop = false;
    let haveMedal = true;
    let winMedal = false;

    // Verificar si la categoría del rol del usuario corresponde con la del plan para dar una bonificación
    if (user.role.category.woocommerce_id === category_id) {
      newPoints = points * 1.5;
    }

    // Se calcula el nuevo puntaje con base en el puntaje anterior y la cantidad de puntos ganada
    let updatedPoints = puntajeEncontrado.amount + newPoints;

    // Si el puntaje encontrado para el usuario tiene 90 puntos quiere decir que ya ha ganado una medalla
    if (puntajeEncontrado.amount != 90) {
      haveMedal = false;
    }

    // Si el nuevo puntaje del usuario sobrepasa los 90 puntos quiere decir que ha alcanzado el tope, entonces se deja en 90
    if (updatedPoints > 90) {
      updatedPoints = 90;
      reachTop = true;
    }

    // Si el nuevo puntaje del usuario alcanza los 90 puntos y no posee una medalla, la gana
    if (updatedPoints === 90 && haveMedal === false) {
      winMedal = true;
    }

    // Se crea el objeto con el nuevo puntaje
    let updatedPoint = {
      amount: updatedPoints,
    };

    try {
      await axios
        .patch(apiUrlPoints + puntajeEncontrado.id, updatedPoint)
        .then((res) => {
          let data = res.data;
          if (data.message === "ok") {
            setPoints(updatedPoints); // Updating points in state
            onPointsChange(updatedPoints);
            if (reachTop) {
              if (winMedal) {
                createMedal(updatedPoints);
              } else {
                Swal.fire({
                  title: "Nice!",
                  text:
                    "You have reached the max amount of points for " +
                    category.name,
                  icon: "success",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              if (winMedal) {
                Swal.fire({
                  title: "Good job!",
                  text:
                    "You got " +
                    newPoints +
                    " " +
                    category.name +
                    " points! Check your profile",
                  icon: "success",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.isConfirmed) {
                    createMedal(updatedPoints);
                  }
                });
              } else {
                Swal.fire({
                  title: "Good job!",
                  text:
                    "You got " +
                    newPoints +
                    " " +
                    category.name +
                    " points! Check your profile",
                  icon: "success",
                  confirmButtonText: "Ok",
                });
              }
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong with your points: " + data.error,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const createMedal = async (currentAmount) => {
    let medal = {
      description: "",
      necessary_points: currentAmount,
      user_id: user.id,
      category_id: category.id,
      name: "",
    };
    medalOptions.forEach((option) => {
      if (option.category === category.name) {
        medal.description = option.description;
        medal.name = option.name;
      }
    });
    try {
      await axios
        .post(apiUrlCreateMedals, medal)
        .then((res) => {
          let data = res.data;
          if (data.message === "ok") {
            onPointsChange(points);
            Swal.fire({
              imageUrl: `${process.env.PUBLIC_URL}/assets/svg/${category.name.toLowerCase()}-obtained-logo.svg`,
              imageHeight: 90,
              imageAlt: "Category Logo",
              title: "Congratulations!",
              text: "You earned a " + category.name + " medal!",
              confirmButtonText: "Ok",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text:
                "You earned a medal, but something went wrong: " + data.error,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const actualToken = window.localStorage.getItem("token");
        if (actualToken != null) {
          const dataUser = await axios.get(apiUrl + actualToken);
          const actualUser = dataUser.data.data;
          try {
            const userCategory = await axios.get(
              apiUrlCategories + category_id
            );
            setCategory(userCategory.data);
          } catch (error) {
            console.error("Error fetching user category:", error);
          }

          setUser(actualUser);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
    fetchData();
  }, []);

  return (
    <div className='single-upconing-card' onClick={clickCard}>
      <div
        className='shadow'
        style={{
          backgroundImage: `url('${bgImgSrc}')`,
        }}
      >
        <img src={`${process.env.PUBLIC_URL}assets/img/tour/1.png`} alt='flag' />
      </div>
      <div className='flag d-flex flex-column'>
        <img src={imgSrc} alt='flag' />
        <img
          src={`${process.env.PUBLIC_URL}assets/svg/star.svg`}
          className='points-icon ml-1 mt-1'
          alt='points-icon'
        />
      </div>
      <div className='content text-center'>
        <h3 className='title text-uppercase'>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default CardLocationsInfo;
