import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer_v2 extends Component {
  componentDidMount() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <footer className='footer-area style-two'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-7'>
              <div className='footer-widget widget text-center travel-living-container'>
                <p className='footer-title'>Colombia Travel and Living</p>
                <div className='widget-contact d-inline-flex'>
                  <p className='telephone text-left'>
                    <i className='fa fa-phone base-color' />
                    <span>+57 322 6366386</span>
                  </p>
                  <p className='location text-left'>
                    <i className='fa fa-envelope-o' />
                    <span>comercial@amak.com.co</span>
                  </p>
                  {/* <p className='text-left'>
                    <i className='fa fa-map-marker' />
                    <span>
                      Calle 23 Norte No. 3N-63 <br /> Cali - Colombia
                    </span>
                  </p> */}
                </div>
                <div className='widget-contact d-inline-flex'>
                  <a
                    href='https://amak.com.co/en/politica-de-tratamiento-de-datos/'
                    target='_blank'
                  >
                    <p className='telephone text-left row align-items-center'>
                      <img
                        src={publicUrl + "assets/img/icons/amak-leaf.svg"}
                        alt='amak-leaf'
                      />
                      <span className='ml-2'>Politics</span>
                    </p>
                  </a>
                  <a href='https://anato.org/' target='_blank'>
                    <p className='telephone text-left row align-items-center'>
                      <img
                        src={publicUrl + "assets/img/icons/amak-leaf.svg"}
                        alt='amak-leaf'
                      />
                      <span className='ml-2'>ANATO</span>
                    </p>
                  </a>
                  <a href='https://www.aerocivil.gov.co/' target='_blank'>
                    <p className='telephone text-left row align-items-center'>
                      <img
                        src={publicUrl + "assets/img/icons/amak-leaf.svg"}
                        alt='amak-leaf'
                      />
                      <span className='ml-2'>Registro Nacional de Turismo</span>
                    </p>
                  </a>
                </div>
              </div>
              <div className='footer-widget widget text-center'>
                <ul className='widget_nav_menu text-center viaje-go-top'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li className='scroll-down'>
                    <a href='#plan_selection'>Available Plans</a>
                  </li>
                  <li className='scroll-down'>
                    <a href='#about_amak'>About Amak</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='footer-image-container'>
              <img
                className='footer-image'
                src={publicUrl + "assets/img/footer-image.png"}
                alt="footer-logo"
              />
            </div>
          </div>
        </div>
        <div className='copyright-inner border-tp-solid'>
          <div className='container pt-3'>
            <div className='row'>
              <div className='col-lg-8 col-md-9'>
                <div className='copyright-text text-left'>
                  <div className='about_us_widget row align-items-center'>
                    <Link to='/' className='footer-logo'>
                      <img
                        src={publicUrl + "assets/img/amak-logo-footer.png"}
                        alt='footerlogo'
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className='d-none col-lg-4 col-md-3 row align-items-center justify-content-between'>
                <div className='text-white-access'>© 2024 Amak Travel</div>
                <ul className='social-icon float-lg-right'>
                  <li>
                    <a
                      className='facebook'
                      href='https://www.facebook.com/codingeek.net/'
                      target='_blank'
                    >
                      <i className='fa fa-facebook  ' />
                    </a>
                  </li>
                  <li>
                    <a
                      className='twitter'
                      href='https://twitter.com/codingeeknet'
                      target='_blank'
                    >
                      <i className='fa fa-twitter  ' />
                    </a>
                  </li>
                  <li>
                    <a
                      className='pinterest'
                      href='https://www.instagram.com/codingeeknet/'
                      target='_blank'
                    >
                      <i className='fa fa-instagram' />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer_v2;
