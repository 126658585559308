import React, { useState } from "react";

export default function PackageFilter({ plans, returnedPlans }) {
  const initialCategoryState = {
    ALL: false,
    ADVENTURE: false,
    CULTURE: false,
    HIKING: false,
  };

  const [categoryState, setCategoryState] = useState(initialCategoryState);

  const handleCategoryClick = (category) => {
    // Setting a true state for the selected category.
    setCategoryState((prev) => ({
      ...initialCategoryState,
      [category]: !prev[category],
    }));

    // Translations object for the selected category.
    let categoryTranslated;
    const categoriesTranslations = {
      ADVENTURE: "Aventura",
      CULTURE: "Cultura",
      HIKING: "Senderismo",
    };

    // Filtering categories based on the user selection.
    if (categoriesTranslations.hasOwnProperty(category)) {
      categoryTranslated = categoriesTranslations[category];

      let filteredPlans = plans.filter((plan) => {
        const planTagName = plan.tags[0].name;
        return planTagName === categoryTranslated;
      });
      returnedPlans(filteredPlans);
    } else {
      returnedPlans(plans);
    }
  };

  return (
    <div className='package-filter-container wow animated fadeInUp'>
      <div
        className={
          categoryState.ALL
            ? "package-filter-item-selected"
            : "package-filter-item"
        }
        onClick={() => handleCategoryClick("ALL")}
      >
        ALL
      </div>
      <div>|</div>
      <div
        className={
          categoryState.ADVENTURE
            ? "package-filter-item-selected"
            : "package-filter-item"
        }
        onClick={() => handleCategoryClick("ADVENTURE")}
      >
        ADVENTURE
      </div>
      <div>|</div>
      <div
        className={
          categoryState.CULTURE
            ? "package-filter-item-selected"
            : "package-filter-item"
        }
        onClick={() => handleCategoryClick("CULTURE")}
      >
        CULTURE
      </div>
      <div>|</div>
      <div
        className={
          categoryState.HIKING
            ? "package-filter-item-selected"
            : "package-filter-item"
        }
        onClick={() => handleCategoryClick("HIKING")}
      >
        HIKING
      </div>
    </div>
  );
}
