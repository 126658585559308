import React from "react";
import { ThemeProvider } from "styled-components";
import ChatBot from "react-simple-chatbot";

const theme = {
  background: "#d9d9d9",
  botBubbleColor: "#f7a502",
  botFontColor: "#303030",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

let publicUrl = process.env.PUBLIC_URL + "/";

const VirtualAssistantChat = (props) => (
  <ThemeProvider theme={theme}>
    <ChatBot
      steps={props.steps}
      botAvatar={publicUrl + "assets/img/toucan-img/virtual-assistant-img.png"}
      userAvatar={publicUrl + "assets/svg/profile-icon.svg"}
      hideHeader={true}
      botDelay={800}
    />
  </ThemeProvider>
);

export default VirtualAssistantChat;
