import React, { useEffect, useState } from "react";
import AddedPlanCard from "../section-components/added-plan-card";
import QuotatePlan from "../section-components/quotate-plan";
import axios from "axios";
import Swal from "sweetalert2";

const SideBar = ({ toggleOpenSidebar, isOpenSidebar }) => {
  const [isOpenQuotate, setIsOpenQuotate] = useState(false);
  const quotations = JSON.parse(localStorage.getItem("quotations"));
  const [quotationsFromStorage, setQuotationsFromStorage] = useState([]);
  const quotationsApiUrl =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/quotations";
  const tokenApiUrl =
    process.env.REACT_APP_API_URL_BASE + "/amak-wonders-api/token/";
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userPhone, setUserPhone] = useState(null);
  const [isLogged, setIsLogged] = useState(false);

  const deleteQuotation = (index) => {
    const updatedQuotations = [...quotations];
    updatedQuotations.splice(index, 1);
    setQuotationsFromStorage(updatedQuotations);
    localStorage.setItem("quotations", JSON.stringify(updatedQuotations));
  };

  useEffect(() => {
    // This function is used to retrieve the current user ID
    async function fetchUserData() {
      try {
        const currentToken = localStorage.getItem("token");
        if (currentToken != null) {
          const userData = await axios.get(tokenApiUrl + currentToken);
          const currentUserId = userData.data.data.id;
          const currentUserName = userData.data.data.name;
          const currentUserPhone = userData.data.data.phone;
          setUserId(currentUserId);
          setUserName(currentUserName);
          setUserPhone(currentUserPhone);
          setIsLogged(true);
        }
      } catch (error) {
        if (error.response.data.message === "Token Expired") {
          localStorage.removeItem("token");
        } else {
          console.error(error.response.data);
        }
      }
    }

    fetchUserData();
  }, []);

  // Toggle for opening or closing the quotation modal.
  const toggleQuotatePlan = async () => {
    if (quotations == null) {
      return;
    }

    if (!isLogged) {
      setIsOpenQuotate(!isOpenQuotate);
    } else {
      const updatedQuotations = quotations.map((quotation) => {
        quotation.user_name = userName;
        quotation.user_phone = userPhone;
        quotation.quotation_date = new Date().toLocaleDateString();
        return quotation;
      });
      await axios.post(quotationsApiUrl, updatedQuotations).then((res) => {
        let data = res.data;
        if (data.message === "Quotations successfully inserted") {
          Swal.fire({
            icon: "success",
            title: "The quotation has been successfully sent!",
          }).then((result) => {
            if (result.isConfirmed) {
              window.localStorage.removeItem("quotations");
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "There has been an error with your quotation",
            text: "Try to quotate it again",
          });
        }
      });
    }
  };

  // Checking if the quotations array has elements and is not null (it is a short function, but the idea of it is not to repeat code when performing this validation).
  const hasQuotations = () => {
    return quotations !== null && quotations.length > 0;
  };

  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div>
      <QuotatePlan
        toggleQuotatePlan={toggleQuotatePlan}
        isOpenQuotate={isOpenQuotate}
      />
      <div className={`sidebar ${isOpenSidebar ? "show" : ""}`}>
        <div className='sidebar-header'>
          <div className='logo'>
            <img
              src={
                publicUrl + "assets/img/toucan-img/virtual-assistant-img.png"
              }
              alt='virtual-assistant-img'
            />
          </div>
          <div className='title'>ADDED PLANS SUMMARY</div>
          <div
            className='close-btn'
            onClick={() => toggleOpenSidebar(!isOpenSidebar)}
          >
            <img
              src={publicUrl + "assets/img/icons/close-side-bar.svg"}
              alt='Close'
            />
          </div>
        </div>
        <div className='added-plans-container'>
          {hasQuotations() ? (
            quotations.map((quotation, quotationIndex) => (
              <AddedPlanCard
                quotation={quotation}
                key={quotationIndex}
                onDelete={() => deleteQuotation(quotationIndex)}
              />
            ))
          ) : (
            <div className='text-center m-4 d-flex flex-column'>
              <h4 className='text-white-access text-bold'>
                You haven't added plans yet
                <span role='img' aria-label='sad-emoji' className='ml-2'>
                  🙁
                </span>
              </h4>
              <p>Start quotating by selecting your preferred plans!</p>
            </div>
          )}
        </div>
        <div className='sidebar-footer'>
          <p>
            If you create an account, your quotations will be saved in the
            “Quoted Plans” section.
          </p>
          <button onClick={toggleQuotatePlan} disabled={!hasQuotations()}>
            MAKE QUOTATION
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
