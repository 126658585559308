import React, { useEffect, useState } from "react";

const VirtualAssistantTutorial = ({
  navbarId,
  isPanelOpen,
  pageType,
  onTextChange,
}) => {
  const [isSkipped, setIsSkipped] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [textIndex, setTextIndex] = useState(0);
  const [textToShow, setTextToShow] = useState("");
  let homeTutorialCompleted = localStorage.getItem("homeTutorialCompleted");
  let planTutorialCompleted = localStorage.getItem("planTutorialCompleted");
  let publicUrl = process.env.PUBLIC_URL + "/";

  useEffect(() => {
    handleTextToShow();
  }, [navbarId]);

  // Closing the tutorial when the user opens the panel
  useEffect(() => {
    if (isPanelOpen) {
      setIsSkipped(true);
    } else if (!isCompleted) {
      setIsSkipped(false);
    }

    if (homeTutorialCompleted) {
      setIsSkipped(true);
    } else {
      setIsSkipped(false);
    }
  }, [isPanelOpen, isCompleted]);

  useEffect(() => {
    // Check if it's the plan page and tutorial is completed
    if (pageType === "planpage" && planTutorialCompleted === "false") {
      setIsSkipped(false);
    }
  }, []);

  // Array of texts to show for the home tutorial
  const homeTutorialTexts = [
    "Welcome to Amak Wonders, The app that helps foreign tourists plan personalized trips to Colombia! I am Yaruk the toucan, nice to meet you!",
    "In the 'Plans' Section, you will find a carousel of available plans. The plans that you add will be saved inside the cart at the top right corner.",
    "At Amak, we specialize in crafting exclusive tours and experiences that responsibly transform destinations through sustainable tourism promotion.",
    "With an account, you can also save your plans in 'Quoted Plans'. I'll be in touch to assist you in signing up, signing in the app, or clarifying any information. Don't hesitate to click me if you need anything!.",
  ];

  // Array of texts to show for the plans tutorial
  const plansTutorialTexts = [
    "Now you are inside the Plans page! Here you can quotate the plan you have selected by choosing the dates, passengers and also adding activities to it.",
    "At the 'Services' section, you can check what this plan includes and make sure it fits your needs.",
    "The 'Locations Info' section shows information regarding the locations that are included in the current plan. This section allows you to obtain points and if you get 90 points, you can earn a medal and with it, obtain travelling benefits.",
    "If this is not what you are looking for, you can check the 'Related Plans' section, where you'll find plans that are part of the same category of the plan you've chosen.",
  ];

  // Conditionally assign the array of tutorial texts depending on the page where the virtual assistant is being rendered at.
  let tutorialTexts =
    pageType === "homepage" ? homeTutorialTexts : plansTutorialTexts;

  // Function to handle showing previous text
  const handlePreviousText = () => {
    if (textIndex > 0) {
      const newIndex = textIndex - 1;
      setTextIndex(newIndex);
      onTextChange(newIndex);
      setTextToShow(tutorialTexts[newIndex]);
    }
  };

  // Function to handle showing next text
  const handleNextText = () => {
    if (textIndex < tutorialTexts.length - 1) {
      const newIndex = textIndex + 1;
      setTextIndex(newIndex);
      onTextChange(newIndex);
      setTextToShow(tutorialTexts[newIndex]);
    } else {
      setIsCompleted(true);
      // If all texts have been shown, close the tutorial container
      setIsSkipped(true);
      localStorage.setItem("homeTutorialCompleted", true);
      if (pageType === "planpage") {
        localStorage.setItem("planTutorialCompleted", true);
      } else {
        localStorage.setItem("planTutorialCompleted", false);
      }
      // Reset text index and text to initial state
      setTextIndex(0);
      setTextToShow(tutorialTexts[0]);
    }
  };

  // Function to handle text based on navbarId
  // The function is ready to work with the Plans Page subnavar ID's, but for some reason the sub-navbar is not detecting the function handleNavbarSelection.
  // TODO: Check the rendering of the subnavar to get to know why it is not working well.
  const handleTextToShow = () => {
    switch (navbarId) {
      case "plans" || "related-plans":
        setTextToShow(tutorialTexts[1]);
        break;
      case "about" || "services":
        setTextToShow(tutorialTexts[2]);
        break;
      case "about" || "locations-info":
        setTextToShow(tutorialTexts[3]);
        break;
      default:
        setTextToShow(tutorialTexts[0]);
        break;
    }
  };

  return (
    <div className={isSkipped ? "d-none" : "tutorial-container-overlay"}>
      {/* General container */}
      <div className='tutorial-container'>
        {/* Tutorial text */}
        <div className='tutorial-content'>{textToShow}</div>
        {/* Navbar text and arrow */}
        <div
          className={
            textIndex > 0
              ? "d-flex align-items-center justify-content-center tutorial-arrow-container my-2"
              : "d-none"
          }
        >
          <img
            src={publicUrl + "assets/svg/arrow-upward.svg"}
            alt='arrow-upward'
            className='tutorial-arrow'
          />
          <div className='text-black-access text-bold'>
            Look at the glowing top menu {" "}
            <span role='img' aria-label='eyes-emoji'>
              👀
            </span>
          </div>
        </div>
        {/* Buttons container */}
        <div className='d-flex align-items-|center justify-content-between mt-2'>
          {/* Previous and next button container */}
          <div className='d-flex align-items-center justify-content-center'>
            <button className='tutorial-next-btn' onClick={handlePreviousText}>
              Prev
            </button>
            <button className='tutorial-next-btn ml-3' onClick={handleNextText}>
              Next
            </button>
          </div>
          <div className='text-black-access text-bold mr-5'>
            {textIndex + 1}/{tutorialTexts.length}
          </div>
          <button
            onClick={() => {
              setIsCompleted(true);
              setIsSkipped(true);
              localStorage.setItem("homeTutorialCompleted", true);
              if (pageType === "planpage") {
                localStorage.setItem("planTutorialCompleted", true);
              } else {
                localStorage.setItem("planTutorialCompleted", false);
              }
            }}
            className='tutorial-skip-btn'
          >
            Skip
          </button>
        </div>
      </div>
    </div>
  );
};

export default VirtualAssistantTutorial;
