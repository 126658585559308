import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const QuotatedPlanCard = ({ quotation }) => {
  return (
    <div className='quotated-plans-card rounded-15 bg-white-access'>
      <img
        src={quotation.image_url}
        alt='quotated-plan-img'
        className='quotated-plans-image rounded-15'
      />
      <div className='d-flex flex-column'>
        <h4 className='text-black-access text-bold'>{quotation.plan_name}</h4>
        <h5 className='text-black-access'>
          Quoted on {quotation.quotation_date}
        </h5>
      </div>
      <div className='d-flex align-items-center flex-column text-center'>
        <h5 className='text-black-access'>From</h5>
        <h5 className='text-black-access'>${quotation.price} USD</h5>
      </div>
      <Link to='/'>
        <button className='quotated-plans-btn text-uppercase text-bold text-center rounded-15'>
          Quotate Again
        </button>
      </Link>
    </div>
  );
};

export default QuotatedPlanCard;
