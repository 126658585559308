// Consider adding a proper documentation
import axios from "axios";

const WooCommerceApi = axios.create({
  baseURL: process.env.REACT_APP_WC_API_BASE_URL,
  auth: {
    username: process.env.REACT_APP_WC_API_CONSUMER_KEY,
    password: process.env.REACT_APP_WC_API_CONSUMER_SECRET,
  },
});

export const fetchPlans = () => {
  return WooCommerceApi;
};
