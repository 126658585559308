import React from "react";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/banner-plan";
import QoutationEngine from "./section-components/quotation-engine";
import UpcomingWorld from "./section-components/locations-info";
import Intro from "./section-components/included-services";
import UpcomingTour from "./section-components/additional-activities";
import Video from "./section-components/video-v2";
import Counter from "./section-components/counter";
import Package from "./section-components/package";
import Client from "./section-components/client";
import BlogSection from "./blog-components/blog-section-v2";
import Ads from "./section-components/ads";
import Instagram from "./section-components/instagram";
import Footer from "./global-components/footer-v2";

const plans_default = () => {
  return (
    <div>
      <Navbar />
      <Banner />
      <QoutationEngine />
      {/* <UpcomingWorld /> */}
      {/* <Intro /> */}
      {/* <UpcomingTour /> */}
      {/* <Video /> */}
      {/* <Counter /> */}
      <Package />
      <Client />
      <BlogSection />
      <Ads />
      <Instagram />
      <Footer />
    </div>
  );
};

export default plans_default;
