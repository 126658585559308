import React from "react";
import PlansAd from "../section-components/plans-ad-home";

const BannerV2 = ({ planInfo, pageType }) => {
  const publicUrl = process.env.PUBLIC_URL + "/";
  const colombiaMap = `${publicUrl}assets/svg/colombia-map.svg`;

  // Function used to convert HTML to plain text so it can be shown in the description
  const convertHtmlToPlainText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <div
      id='home_banner'
      className='main-banner-area jarallax'
      style={{
        backgroundImage: `url(${
          pageType === "planpage" && planInfo.images.length > 0
            ? planInfo.images[0].src
            : pageType === "homepage"
            ? `${publicUrl}assets/img/amak-bg.png`
            : `${publicUrl}assets/img/quotated-plans-bg.jpg`
        })`,
      }}
    >
      {planInfo && pageType === "planpage" ? (
        ""
      ) : (
        <div>
          <img
            src={publicUrl + "assets/svg/arrow-up-white.svg"}
            className='map-arrow-up'
            alt='map-arrow'
          />
          <img
            src={publicUrl + "assets/svg/arrow-down-white.svg"}
            className='map-arrow-down'
            alt='map-arrow'
          />
          <div className='departments-circle-container'>
            <div className='pointer-circle'></div>
            <div className='departments-circle'></div>
            <div className='extra-circle1'></div>
            <div className='extra-circle2'></div>
          </div>
        </div>
      )}
      {/* Colombia Map and Department Name*/}
      <div className='content'>
        <div className='map-content'>
          <h1 className='department-name'>
            {planInfo ? planInfo.name : "VALLE DEL CAUCA"}
          </h1>
          <img className='img-map' src={colombiaMap} alt='img-map' />
        </div>
        {pageType === "planpage" ? (
          <div className='banner-plan-description-container'>
            <p className='banner-plan-description-text'>
              {convertHtmlToPlainText(planInfo.description)}
            </p>
            {/* <img
              src={publicUrl + "assets/svg/toucan-amak.svg"}
              alt='toucan-amak'
              className='banner-plan-description-img'
            /> */}
          </div>
        ) : null}
        <div className='container'>
          <div className='scroll-down'>
            {planInfo ? pageType === "planpage" : <PlansAd />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerV2;
