import React, { useState } from "react";
import SelectRole from "../section-components/select-role";

const Register = ({ onCloseRegister, register, onCloseSignIn, signIn }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [openSelectRole, setOpenSelectRole] = useState(false);

  let user = {
    name: name,
    phone: phone,
    email: email,
    password: password,
  };

  // Checking if the user filled out all of the fields
  function checkAllPropertiesFilled(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (!obj[key]) {
          return false; // If any property is falsy (null, undefined, empty string, etc.), return false
        }
      }
    }
    return true; // If all properties have values, return true
  }

  const handleCloseModalSelectRole = (dataFromModalSelectRole) => {
    setOpenSelectRole(dataFromModalSelectRole);
  };

  const sendUserToParent = (e) => {
    if (checkAllPropertiesFilled(user)) {
      e.preventDefault();
      setOpenSelectRole(!openSelectRole);
    }
  };

  return (
    <div className='signInOverlay'>
      <form className='registerForm'>
        <h3>Sign Up!</h3>

        <input
          required
          className='nameInput'
          type='text'
          placeholder='Name'
          id='name'
          onChange={(e) => setName(e.target.value)}
        />

        <input
          required
          className='phoneInput'
          type='text'
          placeholder='Phone Number'
          id='phone'
          onChange={(e) => setPhone(e.target.value)}
        />

        <input
          required
          className='registerEmailInput'
          type='text'
          placeholder='Email'
          id='email'
          onChange={(e) => setEmail(e.target.value)}
        />

        <input
          required
          className='registerPassInput'
          type='password'
          placeholder='Password'
          id='password'
          onChange={(e) => setPassword(e.target.value)}
        />

        <button
          type='submit'
          className='registerButton'
          onClick={sendUserToParent}
        >
          CONTINUE
        </button>
        {/* In case we want to add external authentication */}
        {/* <div class='registerSocial'>
          <div className='googleIcon'>
            <img src={publicUrl + "assets/img/social.png"} />
          </div>
          <div class='fb'>
            <img src={publicUrl + "assets/img/facebook.png"} />
          </div>
        </div> */}
        <div className='register-text'>
          <p>Already have an account?</p>
          <p
            onClick={() => {
              onCloseRegister(!register);
              onCloseSignIn(!signIn);
            }}
          >
            Sign In
          </p>
        </div>
        <button
          className='closeButton'
          onClick={() => {
            onCloseRegister(!register);
          }}
        >
          <img src={publicUrl + "assets/img/icons/close.svg"} alt='Close' />
        </button>
      </form>
      {openSelectRole && (
        <SelectRole
          name={name}
          phone={phone}
          email={email}
          password={password}
          onCloseSelectRole={handleCloseModalSelectRole}
          openSelectRole={openSelectRole}
        />
      )}
    </div>
  );
};

export default Register;
