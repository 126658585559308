import React from "react";

export default function PlansAdHome() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div className='courses-container'>
      <div className='course'>
        <a className='course-preview' href='#plan_selection'>
          <h5>QUOTATE NOW</h5>
        </a>
        <div className='course-info'>
          <h6>Plans in 3 different categories</h6>
          <div className='category-items-container'>
            <div className='category-item'>
              <img
                src={publicUrl + "assets/svg/hiking-icon.svg"}
                alt='category-icon'
              />
              <div>Hiking</div>
            </div>
            <div className='category-item'>
              <img
                src={publicUrl + "assets/svg/culture-icon.svg"}
                alt='category-icon'
              />
              <div>Culture</div>
            </div>
            <div className='category-item'>
              <img
                src={publicUrl + "assets/svg/adventures-icon.svg"}
                alt='category-icon'
              />
              <div>Adventure</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
