import React from "react";
import { Link } from "react-router-dom";

const PlanCard = ({ planInfo, pageType, onCardClick }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const adventureIcon = `${publicUrl}assets/svg/adventures-icon.svg`;
  const cultureIcon = `${publicUrl}assets/svg/culture-icon.svg`;
  const hikingIcon = `${publicUrl}assets/svg/hiking-icon.svg`;
  const noCategoryIcon = `${publicUrl}assets/svg/no-category.svg`;

  const plansCategories = [
    { spName: "Aventura", engName: "Adventure", icon: adventureIcon },
    { spName: "Cultura", engName: "Culture", icon: cultureIcon },
    { spName: "Senderismo", engName: "Hiking", icon: hikingIcon },
  ];

  const getCategoryInfo = (planTags) => {
    if (planTags.length > 0) {
      const foundCategory = plansCategories.find(
        (category) => category.spName === planTags[0].name
      );

      const { engName, icon } = foundCategory;
      return { engName, icon };
    }

    return { engName: "No Category", icon: noCategoryIcon };
  };

  const storeId = () => {
    // Store the ID of the clicked package in localStorage
    localStorage.setItem("planId", planInfo.id);
    if (pageType === "planpage") {
      onCardClick();
    }
  };

  return (
    <div className='col-xl-12 col-md-10 col-sm-6'>
      <Link to='/plan-page' onClick={storeId}>
        <div
          className='single-package-card wow animated fadeInUp'
          data-wow-duration='0.4s'
          data-wow-delay='0.1s'
        >
          <div className='thumb'>
            <img
              style={{ maxWidth: "fit-content", maxHeight: "380px" }}
              src={planInfo.images[0].src}
              alt='plan-img'
            />
          </div>
          <div className='details text-center'>
            <div className='location'>
              <div className='tp-review-meta'>
                <img
                  src={getCategoryInfo(planInfo.tags).icon}
                  alt='category-icon'
                />
                <div className='text-bold'>
                  {getCategoryInfo(planInfo.tags).engName}
                </div>
              </div>
            </div>
            <h4>{planInfo.name}</h4>
            <h5>From</h5>
            <h4>{planInfo.price !== "" ? planInfo.price : 0} USD</h4>
            <p className='text-black-access d-flex align-items-center justify-content-center text-bold'>
              Get 90 {getCategoryInfo(planInfo.tags).engName} points to earn a
              medal
              <img
                src={`${publicUrl}assets/svg/star.svg`}
                className='points-icon ml-1'
                alt='points-icon'
              />
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PlanCard;
