import React, { useState, useEffect } from "react";
import { fetchPlans } from "../../api";
import PackageFilter from "./package-filter";
import PlansCarousel from "./plans-carousel";

// Componens rendering order: package -> plans-carousel -> plan-card

function Package() {
  const [plans, setPlans] = useState([]);
  const [filteredPlans, setFilteredPlans] = useState([]);

  // Retrieving plans from the WooCommerce API.
  useEffect(() => {
    async function fecthWoocommerceData() {
      // Fetching data using the "api" file exported function
      const woocommercePlans = await fetchPlans().get("/products");
      setPlans(woocommercePlans.data);
    }
    fecthWoocommerceData();
  }, []);

  // Retrieving and setting filtered plans from the package-filter component
  const handleReturnedPlans = (data) => {
    setFilteredPlans(data);
  };

  let publicUrl = process.env.PUBLIC_URL + "/";


  return (
    <div id='plan_selection' className='package-area pd-top-108 mg-bottom-92'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-6 col-lg-8'>
            <div className='section-title text-center'>
              <h2
                className='text-white-access title wow animated fadeInUp'
                data-wow-duration='0.6s'
                data-wow-delay='0.1s'
              >
                AVAILABLE PLANS!
              </h2>
              <h5
                className='text-white-access wow animated faFdeInUp'
                data-wow-duration='0.6s'
                data-wow-delay='0.2s'
              >
                Enjoy the great wonders of Valle del Cauca
              </h5>
            </div>
          </div>
        </div>

        {/* Packages Filter */}
        <PackageFilter plans={plans} returnedPlans={handleReturnedPlans} />
        {/* Packages Carousel */}
        {plans.length > 0 ? (
          <PlansCarousel plans={plans} filteredPlans={filteredPlans} />
        ) : (
          <div className="d-flex justify-content-center">
            <img src={publicUrl + "assets/svg/loading-spinner.svg"} alt='logo' />
          </div>
        )}
        <h6
          className='text-white-access wow animated faFdeInUp text-center'
          data-wow-duration='0.6s'
          data-wow-delay='0.2s'
        >
          *The published prices are per person according to the specific
          conditions of each plan.
        </h6>
      </div>
    </div>
  );
}

export default Package;
